<template>
    <div class="ztb_title w100p ">
    <span class="f20 dib">厂商平台</span>
    </div>
    <ul class="w100p ent_list_1">
    <li v-for="item in companyList" :key="item.id">
        <router-link
        :to="{ path: '/companyDetail', query: { id: item.id } }"
        class="db"
        >
        <img :src="getImgUrl(item.companyLogo)" class="db auto" />
        <div class="content_box">
            <div class="title f16 otw w100p">{{ item.companyName }}</div>
            <div class="desc f14 c999 ovh cf">
            <i
                v-if="item.vendorsState === '已认证'"
                class="iconfont icon-renzheng green fl ml30 f20"
            ></i>

            <span class="c999 f14 fr">{{ item.companyAddress }}</span>
            <i class="fr iconfont icon-map"></i>
            </div>
        </div>
        </router-link>
    </li>
    </ul>
    <div class="more w100p tc mb30">
    <i class="iconfont icon-menu"></i>
    <span class="show-more" @click="showMore()">查看更多</span>
    </div>

</template>
<script setup>
import { onMounted, ref, defineComponent } from "vue";
import $api from "@/api";
import { getImgUrl } from "@/utils/imageRelated";
import { useRouter } from "vue-router";

const router = useRouter();

//#region 厂商平台
const companyList = ref([]);
const getCompanyList = async () => {
    const [err, data] = await $api.company.getCompanyList({
        PageIndex: 1,
        PageSize: 4,
    });
    if (!data.succeeded) return;
    companyList.value = data.data.items;
};
//#endregion

onMounted(() => {
    getCompanyList();
});
</script>
<style scoped lang="less">

.ztb_title{
    margin-top: 30px;
    margin-bottom: 20px;
    // border-bottom:1px solid #efefef;
    box-shadow:10px 10px 20px -20px #efefef;
    background:url("../../../assets/images/blue_line.png") right center repeat-x;
    span{
        display: inline-block;
        padding:0 10px;
        background:#FBFBFA;
    }
}

.ent_list_1{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ent_list_1>li{
    background:none;
    width:45%;
    border:1px solid #efefef;
    transition-duration: 1s;
    margin-bottom: 20px;
    

    a{
        width:100%;
        padding:0;
        background:none;
    }

    .title{
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        background:none;
        transition-duration: 1s;
    }

    img{
        width:80%;
    }

    .desc{
        padding:0 10%;
    }
}

li:hover{
    box-shadow:10px 10px 20px -15px #1188f7;
    .title{
        color:#1188f7;
    }
}

.more{
    background: #efefef;
    color: #333;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: #212529;
    transition: all 0.3s 0s ease-in-out;
    -webkit-transition: all 0.3s 0s ease-in-out;
    border-radius: 5px;
}

.more:hover{
    background:#1188f7;
    color:#fff;
}
</style>