// 首页左侧文章分类与列表
import {
  ref,
  onMounted,
  reactive
} from "vue"
import * as Swiper from "@/utils/ui/swiper.min.js"
import $api from "@/api"
export default function getArticleType_list() {
  const pagination=reactive({
    Title:"",
    TypeId:"",
    PageIndex:1,
    PageSize:15
  })
  const articleTypes = ref([{id:-1,name:"全部资讯",active:true}])
  const articleListInfo=reactive({list:{}})
  const nothing=ref(false)
  // 获取文章分类
  const getArticleType = async () => {
    const [err, data] = await $api.article.getArticleType(1)
    if(!data.succeeded) return;
    articleTypes.value=articleTypes.value.concat(...data.data.map((e,i) => {
      e.name = e.name.replace("分类", "")
      e.active=false
      return e
    }))

  }
  // 获取文章列表
  const getArticleList=async (id=-1)=>{
    articleTypes.value.map(e=>{
      e.id===id?e.active=true:e.active=false;
      return e
    })
    const [err,{data:articleListDetail}]=await $api.article.getArticleList(Object.assign(pagination,{TypeId:id}));
    if(!articleListDetail?.items?.length){
      nothing.value=true
    }else{
      nothing.value=false
    };
   
    articleListInfo.list=articleListDetail
  }
  onMounted(async () => {
    await getArticleType()
    await getArticleList()
    new Swiper(".title-swiper-container", {
      slidesPerView: 6,
      spaceBetween: 20,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
    // 轮播
    new Swiper('.swiper-container', {
      autoplay: true,//可选选项，自动滑动
      pagination: {
        el: '.swiper-pagination',
        clickable :true,
      },
    });
    // end轮播

  })
  return {
    articleTypes,articleListInfo,getArticleList,nothing
  }
}