<template>
    <!-- 政策推荐 & 会员中心 -->
    <div class="sidebar_box">
          <div class="title">
            <span>{{title}}</span>
          </div>
          <router-link v-for="item in meeting" key="item.id" :to="{ path: '/newsDetail', query: { id: item.id } }" class="db w100p">
            <img :src="getImgUrl(item.image)" class="w100p mb20">
          </router-link>

          <!-- <router-link :to="{path:extra?'/memberHome':'library'}" class="db w100p">
            <img :src="require(`@/assets/images/${extra?'mb':'hot'}.png`)" class="w100p mb20">
          </router-link>
          <router-link :to="{path:extra?'/memberHome':'library'}" class="db w100p">
            <img :src="require(`@/assets/images/${extra?'mb':'hot'}.png`)" class="w100p mb20">
          </router-link>
          <router-link :to="{path:extra?'/memberHome':'library'}" class="db w100p">
            <img :src="require(`@/assets/images/${extra?'mb':'hot'}.png`)" class="w100p mb20">
          </router-link> -->
            <div v-if="extra" class="title_2 flex-bt bbc">
          <span class="f20">{{extra.tips}}</span>
          <router-link :to="{path:'/bidding'}" v-if="extra.showMore">查看更多>></router-link>
        </div>
          <ul v-if="extra">
            <li v-for="item in bids" :key="item.id">
              <a href="javascript:void(0);" @click="showDetail(store,router,'bidding','BiddingDetail',item)"><span class="type">{{item.projectType}} </span><span>{{item.title}}</span></a>
            </li>
          </ul>
          <ul v-else>
            <li v-for="item in policies" :key="item.id">
             <a href="javascript:void(0);" @click="showDetail(store,router,'library','LibraryDetail',item)"><span>{{item.title}}</span></a>
            </li>
          </ul>
        </div>
</template>
<script setup>
import $api from "@/api";
import { computed, defineComponent,onMounted,ref, watch } from 'vue'
import useArticles from "@/composable/home/useArticles"
import {useStore,mapGetters} from "vuex"
import {useRouter} from "vue-router"
import showDetail from "@/composable/universal/showDetail"
import { getImgUrl, errPic } from "@/utils/imageRelated";
const props = defineProps({
        title:String,
        extra:{
            type:Object,
            default:()=>null
        }
    });
const store=useStore()
const router=useRouter()
const { policies,bids}=useArticles(props.extra)

//#会议 
const meeting = ref([]);
const getBidAnalyze = async () => {
  const [err, data] = await $api.article.getArticleList({ TypeId: 46 });
  if (!data.succeeded) return;
  meeting.value = data.data.items.slice(0,3);
};
//#end 会议

onMounted(()=>{
  getBidAnalyze()
})

</script>
<style lang="less" scoped>
.sidebar_box li a {
      width: 400px;
      color: #212529;
      font-size: 16px;
      text-align: left;
    }
.sidebar_box img{
  border-radius: 5px;
}
.type{color:#1188f7;margin-right: 5px;word-spacing: 1px;}
</style>