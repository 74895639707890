<template>
  <div id="index">
    <div class="banner">
      <div class="left_banner">
        <!-- <div class="block">
          <el-carousel trigger="click" height="350px" >
            <el-carousel-item v-for="item in leftSwiperList" :key="item">
              <a :href="item.link" target="_black" >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item.image"
                  fit="cover"
                  :alt="item.describe"
                ></el-image>
              </a>
              <span class="dib otw remark" >{{item.describe}}</span>
            </el-carousel-item>
          </el-carousel>
        </div> -->

        <div class="swiper-container">
          <div class="swiper-wrapper">
            <a class="swiper-slide db rel" v-for="item in leftSwiperList" :key="item" :href="item.link" target="_black">
              <img :src="item.image" class="w100p h100p">
              <span class="abs otw">{{item.describe}}</span>
            </a>
          
            
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
        </div>

      </div>
      <div class="right_banner">
        
        <div class="h100p">
          <a class="rel fl"  v-for="(item,index) in rightSwiperList" :href="item.link" target="_blank" :key="item.position" :class="[!index?'right-top-pic':'right-bottom-pic']">
            <img
              :src="item.image"
              class="w100p h100p"
              @error="$event.target.src=require('@/assets/images/break.svg')"
            />
            <span class="abs otw"
              >{{item.describe}}</span
            >
          </a>
        </div>

      </div>
    </div>

    <main class="ztb_main policy_main">
      <div class="ztb_box">
        <!-- 文章列表 -->
        <div class="pl_list_box">
          <article-class></article-class>
          
          <!-- 厂商列表 -->
          <!-- <div class="ztb_title w100p ">
            <span class="f20 dib">厂商平台</span>
          </div>
          <ul class="w100p ent_list_1 ">
            <li v-for="item in companyList.items" :key="item.id">
              <router-link
                :to="{ path: '/companyDetail', query: { id: item.id } }"
                class="db"
              >
                <img :src="getImgUrl(item.companyLogo)" class="db auto" />
                <div class="content_box">
                  <div class="title f16 otw w100p">{{ item.companyName }}</div>
                  <div class="desc f14 c999 ovh cf">
                    <i
                      v-if="item.vendorsState === '已认证'"
                      class="iconfont icon-renzheng green fl ml30 f20"
                    ></i>

                    <span class="c999 f14 fr">广东</span>
                    <i class="fr iconfont icon-map"></i>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
          <div class="more w100p tc mb30">
            <i class="iconfont icon-menu"></i>
            <span class="show-more" @click="showMore()">查看更多</span>
          </div> -->
        </div>
      </div>
      <div class="ztb_latest pb30">
        <policies-recomm title="会议论坛" ></policies-recomm>
        <!-- <policies-recomm title="政策推荐"></policies-recomm>
        <policies-recomm
          title="会员精选"
          :extra="{ tips: '医信招投标信息', showMore: true}"
        ></policies-recomm> -->

        <!-- <div class="title">
          <span>MILC入口</span>
        </div>
        <a href="http://milc.hitzone.cn/#/" target="_blank" class="db w100p">
          <img src="../../assets/images/milc.png" class="w100p mb20" />
        </a> -->
        <companies></companies>

        <div class="title">
          <span>行业伙伴</span>
        </div>
        <div class="sidebar_box">
          <div class=" rel">
            <el-carousel
              height="150px"
              :autoplay="false"
              indicator-position="none"
              arrow="always"
              :loop="false"
            >
              <el-carousel-item v-for="item in 2" :key="item">
                <a href="javascript:void(0)" target="_blank" class="db partner">
                  <img
                    v-for="val in partner"
                    :src="val.url"
                    :key="val.url"
                    class="db w100p h100p partner-item"
                  />
                </a>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
// @ is an alias to /src
import { onMounted, ref, defineComponent } from "vue";
import { useRouter } from "vue-router";
import $api from "@/api";
import { getImgUrl } from "@/utils/imageRelated";
import ArticleClass from "./components/articleClass.vue";
import PoliciesRecomm from "./components/policiesRecomm.vue";
import Companies from "./components/companies.vue";
defineComponent({
  ArticleClass,
  PoliciesRecomm,
  Companies
});
const router = useRouter();

//#region 招投标分析
const bidAnalyze = ref([]);
const getBidAnalyze = async () => {
  const [err, data] = await $api.article.getArticleList({ TypeId: 38 });
  if (!data.succeeded) return;
  bidAnalyze.value = data.data;
};
//#endregion

//#region 厂商平台
const companyList = ref([]);
const getCompanyList = async () => {
  const [err, data] = await $api.company.getCompanyList({
    PageIndex: 1,
    PageSize: 4,
  });
  if (!data.succeeded) return;
  companyList.value = data.data;
};
//#endregion

const showMore = () =>router.push({name: "Company",});

let leftSwiperList = ref([
]);
let rightSwiperList  = ref([])

const getBannerPics = async () => {
  const [err, data] = await $api.user.getBannerPics();
  if (!data.succeeded) return;
  leftSwiperList.value = leftSwiperList.value.concat(
    data.data.filter(e=>e.position===1).map((e) => {
      e.image = getImgUrl(e.image);
      return e;
    })
  );
  
  rightSwiperList.value=data.data.filter(e=>e.position!==1).splice(0,3).map((e) => {
      e.image = getImgUrl(e.image);
      return e;
    })
};
const partner = ref([
  { url: require("@/assets/images/sohu.png") },
  { url: require("@/assets/images/bytedance.png") },
  { url: require("@/assets/images/one.png") },
]);
const errPic=(event)=>{
  console.log(event);
}
onMounted(() => {
  getBidAnalyze();
  getCompanyList();
  getBannerPics();

});
</script>
<style lang="less" scoped>
.policy_main .ztb_latest .sidebar_box {
  background: none;
}
.ztb_main .ztb_box {
  width: 700px;
}

.policy_main .ztb_box .article-list {
  padding-right: 0;
}

.policy_main .ztb_box .article-list > li {
  padding: 15 0;
  border: none;
}
.title {
  text-align: center;
}
.show-more {
  cursor: pointer;
}
.partner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.partner-item {
  width: 107px;
  height: 107px;
  border-radius: 50%;
  margin: 0 auto;
}
:deep(.el-carousel__arrow) {
  width: 18px;
  height: 18px;
}
:deep(.el-carousel__arrow--left) {
  left: 0;
}
:deep(.el-carousel__arrow--right) {
  right: 0;
}
.right-top-pic{
  width: 100%;
  height: 200px;
}
.right-bottom-pic{
  width: 50%;
  margin-top: 14px;
  width: 208px;
}
.right-bottom-pic:first-of-type{
  float: left !important;
}
.right-bottom-pic:last-of-type{
  float: right !important;
}

// 2020 10 21
.banner .right_banner span{background:rgba(17,225,247,50%);}
.right-bottom-pic{height: 137px;}
.banner .right_banner span{padding:7px 15px;}
.banner .left_banner span{padding:10px 20px 20px 20px;}

// banner-nav-bar
/deep/.el-carousel__indicators--horizontal{right: 0;left:auto;transform:none;margin-right: 20px;}
.swiper-container{height: 350px;}
.banner .left_banner span{background:rgba(17, 136, 247,0.5);}
.banner .left_banner .swiper-pagination{bottom:5px;}
/deep/.swiper-pagination-bullet{cursor:pointer;}

.ztb_latest{
  width:440px;
}
</style>
