import {onMounted,reactive, ref} from "vue"
import $api from "@/api"
export default function useArticles(isPolicies){
    const policiesParams=reactive({
        Number:5,
        IsHot:2
    })
    const policies=ref([])
    const bids=ref([])
    const getPoliciesTop5=async()=>{
        const [err,data] =await $api.article.getPoliciesTop5(policiesParams);
        if(!data.succeeded) return;
        policies.value=data.data;
        
    }
    const getBidTop5=async ()=>{
        const [err,data]=await $api.article.getBidTop5({Number:5})
        if(!data.succeeded) return;
        bids.value=data.data;
    }
    onMounted(()=>{
            getPoliciesTop5()
            getBidTop5()
    })
    return {
        policies,
        bids
    }
}