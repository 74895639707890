<template>

  <!-- 首页左侧推荐文章 -->
  <div class="title_swiper">
    <div class="title-swiper-container rel">
      <div class="swiper-wrapper">
        <div 
          class="swiper-slide"
          v-for="(type,index) in articleTypes"
          :key="type.id"
          @click="getArticleList(type.id)"
          :class="{ active: type.active }">
          {{ type.name }}
        </div>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </div>
  <div style="min-height:250px">
    <ul class="pl_list article-list" v-if="!nothing">
      <li v-for="item in list.items" :key="item.id">
        <router-link
          :to="{ path: '/newsDetail', query: { id: item.id } }"
          class="db w100p item"
        >
          <div class="img_box">
            <img v-if="item.image" :src="getImgUrl(item.image)" class="db" />
            <img v-else src="" @error="errPic($event)" class="db" />
          </div>

          <div class="brief w100p">
            <div class="title">{{ item.title }}</div>

            <div class="intro">{{ item.descrption }}</div>

            <div class="date_price cf">
              <div class="date fl" v-formatTime>{{ item.createdTime }}</div>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
    <no-data v-else class="nothing"></no-data>
  </div>
  <div class="more w100p tc">
    <i class="iconfont icon-menu"></i>
    <span class="show-more" @click="showMore()">查看更多</span>
  </div>
</template>
<script setup>
import { defineComponent, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import useArticleClass from "@/composable/home/useArticleClass";
import NoData from "@/components/nothing/noData.vue";
import { getImgUrl, errPic } from "@/utils/imageRelated";

const router = useRouter();
    const {
      articleTypes,
      articleListInfo,
      getArticleList,
      nothing,
    } = useArticleClass();

    const showMore = () => {
      router.push({
        name: "News",
      });
    };
    const {list}=toRefs(articleListInfo)
</script>
<style lang="less" scoped>
.title,
.intro {
  text-align: left;
}
.nothing {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  padding-top: 50px;
}
.show-more {
  cursor: pointer;
}
.active {
  color: #ff6501;
}
.swiper-slide {
  // padding: 0 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  line-height: 60px;
}



.policy_main .ztb_box .article-list{margin-top:0;}
</style>
